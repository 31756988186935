import React from "react";

const ProgressBar = ({ percentage = 1 }) => (
	<div className="w-full h-1 bg-slate-50 rounded-full">
		<div
			style={{ width: `${percentage}%`, transition: "width 1s" }}
			className="h-full bg-darkBlue rounded-full"
		/>
	</div>
);

export default ProgressBar;
