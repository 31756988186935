import fetch from "unfetch";
import { BASE_URL } from "../globals";

/**
 *
 * @returns
 */
const GenerateAppeal = async (
	first_name,
	last_name,
	plain_text,
	medication,
	denial_reason,
	other_notes
) => {
	const url = `https://serverintelliga.com/generateLetter`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({
			first_name,
			last_name,
			plain_text,
			medication,
			denial_reason,
			other_notes,
		}),
	});

	if (res.ok) {
		const data = await res.json();
		console.log(data);
		return { status: true, data: data.reply };
	} else {
		console.log("Error in res.");
		return { status: false };
	}
};
export default GenerateAppeal;
