import React, { useEffect, useState } from "react";
import FileUpload from "../components/FileUpload";
import Lottie from "react-lottie-player";
import loading_one from "../assets/loading_one.json";
import ProgressBar from "../components/ProgressBar";

const TOTAL_TIME = 30;
const MAX_PERCENTAGE = 80;

const StepOne = ({ loading, uploadFile, handleClick }) => {
	const [percentage, setPercentage] = useState(1);

	useEffect(() => {
		const interval = setInterval(() => {
			setPercentage((oldPercentage) => {
				if (oldPercentage >= MAX_PERCENTAGE) {
					clearInterval(interval);
					return oldPercentage;
				}
				return oldPercentage + 1;
			});
		}, TOTAL_TIME * 10); // progresses 1% every 200ms

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (!loading) {
			setPercentage(1);
		}

		return () => {
			setPercentage(1);
		};
	}, [loading]);

	return (
		<div className="w-full flex flex-col items-center">
			{!loading && (
				<div className="w-1/2 flex flex-col items-center">
					<FileUpload
						handleClick={handleClick}
						uploadFile={uploadFile}
					/>
				</div>
			)}

			{loading && (
				<div className="mx-auto my-auto w-letterWidth h-full flex flex-col">
					<div className="w-full h-96 border border-2 border-background3 border-dashed rounded-sm bg-slate-50 flex flex-col mx-auto">
						<Lottie
							animationData={loading_one}
							className="mx-auto my-auto"
							play
							style={{ width: 250, height: 250 }}
						/>
					</div>

					<div className="w-full mt-4">
						<ProgressBar percentage={percentage} />
					</div>
				</div>
			)}
		</div>
	);
};

export default StepOne;
