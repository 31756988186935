import fetch from "unfetch"
import { BASE_URL } from "../globals";

/**
 *
 * @returns
 */
const TrialUpload = async (files) => {
    console.log(files)
	const url = `${BASE_URL}/api/trial/trialupload`;
	const res = await fetch(url, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `${localStorage.getItem("auth")}`,
		},
		body: JSON.stringify({
			base64: files,
		}),
	});

	if (res.ok) {
		const data = await res.json();
	
		return { status: true, data: data.data };
	} else {
		return { status: false };
	}
};
export default TrialUpload;
