import React, { useRef, useState } from "react";
import Button from "../components/Button";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Modal from "../components/Modal";
import { InlineWidget } from "react-calendly";

const StepThree = ({ appeals }) => {
	const [appeal, setAppeal] = useState(appeals);
	const appealRef = useRef();

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	const [modalTitle, setModalTitle] = useState("TITLE");
	const [modalDescription, setModalDescription] = useState(
		"We will go ahead and describe things here"
	);

	const handleLetterChange = (e) => setAppeal(e.target.value);

	const handleCopyClick = async () => {
		try {
			await navigator.clipboard.writeText(
				appeal?.replace(/(<([^>]+)>)/gi, "")
			);

			toast("Appeal copied", { type: "success" });
		} catch (error) {
			console.error("Failed to copy:", error);
		}
	};

	const handleDownloadClick = () => {
		if (!appeals) {
			toast("No content to download", { type: "warning" });
			return;
		}

		try {
			const doc = new jsPDF("p", "pt"); // set PDF to portrait mode
			const lines = appeal
				.split("\n")
				.map((line) => doc.splitTextToSize(line, 800))
				.flat();

			doc.autoTable({
				// create a table with a single cell
				body: [[lines.join("\n")]],
				theme: "plain",
				styles: {
					cellWidth: "wrap",
					minCellHeight: "auto",
					overflow: "linebreak",
				}, // ensure cell width is wrapped, height is automatic, and text is line-broken
			});

			doc.save("Appeal.pdf");
			toast("Appeal downloaded", { type: "success" });
		} catch (error) {
			toast("Failed to download appeal", { type: "error" });
			console.error("Failed to download:", error);
		}
	};

	const signatureModalText = () => {
		setModalTitle("Book a demo");
		setModalDescription(
			"You can add a custom signature with Intelliga's full platform."
		);
	};

	const saveModalText = () => {
		setModalTitle("Book a demo");
		setModalDescription(
			"You can save and manage all your appeals with Intelliga's full platform."
		);
	};

	const insuranceModalText = () => {
		setModalTitle("Book a demo");
		setModalDescription("Send denial appeals directly to insurance.");
	};

	return (
		<div className="w-full flex justify-center">
			<textarea
				className="px-10 text-sm py-12 h-letterHeight w-letterWidth bg-slate-50 border border-grayBorder flex flex-col items-center justify-center self-center ml-96 transition-all focus:border-gray outline-none mb-12 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
				style={{ fontFamily: "Times New Roman", height: "776.4px" }}
				onChange={handleLetterChange}
				value={appeal}
				ref={appealRef}
			/>

			<div className="ml-8 bg-red w-96">
				<Button onClick={handleDownloadClick}>
					<svg
						width="12"
						height="14"
						viewBox="0 0 12 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="mr-2"
					>
						<path
							d="M5.99935 0.332031C6.36755 0.332031 6.66602 0.630511 6.66602 0.998698V8.0559L8.19462 6.5273C8.45495 6.26696 8.87708 6.26696 9.13742 6.5273C9.39775 6.78763 9.39775 7.20976 9.13742 7.4701L6.47075 10.1368C6.21042 10.3971 5.78828 10.3971 5.52795 10.1368L2.86128 7.4701C2.60093 7.20976 2.60093 6.78763 2.86128 6.5273C3.12163 6.26696 3.54374 6.26696 3.80409 6.5273L5.33268 8.0559V0.998698C5.33268 0.630511 5.63115 0.332031 5.99935 0.332031ZM1.33268 10.332C1.70087 10.332 1.99935 10.6305 1.99935 10.9987V12.332H9.99935V10.9987C9.99935 10.6305 10.2978 10.332 10.666 10.332C11.0342 10.332 11.3327 10.6305 11.3327 10.9987V12.332C11.3327 13.0684 10.7357 13.6654 9.99935 13.6654H1.99935C1.26297 13.6654 0.666016 13.0684 0.666016 12.332V10.9987C0.666016 10.6305 0.964496 10.332 1.33268 10.332Z"
							fill="white"
						/>
					</svg>
					Download appeal
				</Button>

				<div className="mt-4" />

				<Button onClick={handleCopyClick}>
					<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="mr-2"
					>
						<path
							d="M0.333984 1.66536C0.333984 0.928985 0.930938 0.332031 1.66732 0.332031H8.33398C9.07038 0.332031 9.66732 0.928985 9.66732 1.66536V4.33203H12.334C13.0704 4.33203 13.6673 4.92898 13.6673 5.66536V12.332C13.6673 13.0684 13.0704 13.6654 12.334 13.6654H5.66732C4.93094 13.6654 4.33398 13.0684 4.33398 12.332V9.66536H1.66732C0.930938 9.66536 0.333984 9.06843 0.333984 8.33203V1.66536ZM5.66732 9.66536V12.332H12.334V5.66536H9.66732V8.33203C9.66732 9.06843 9.07038 9.66536 8.33398 9.66536H5.66732ZM8.33398 8.33203V1.66536H1.66732V8.33203H8.33398Z"
							fill="white"
						/>
					</svg>
					Copy appeal
				</Button>

				<div className="mt-16" />
				<Button
					onClick={() => {
						toggle();
						signatureModalText();
					}}
				>
					<svg
						fill="none"
						viewBox="0 0 24 24"
						height="14"
						width="14"
						className="mr-2"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							xmlns="http://www.w3.org/2000/svg"
							d="M17.7071 2.29289C17.3166 1.90237 16.6834 1.90237 16.2929 2.29289L14 4.58579L12.7071 3.29289C12.3166 2.90237 11.6834 2.90237 11.2929 3.29289L5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071C5.68342 11.0976 6.31658 11.0976 6.70711 10.7071L12 5.41421L12.5858 6L10.2929 8.29289L3.29289 15.2929C3.10536 15.4804 3 15.7348 3 16V20C3 20.5523 3.44772 21 4 21H8C8.26522 21 8.51957 20.8946 8.70711 20.7071L15.7071 13.7071L21.7071 7.70711C22.0976 7.31658 22.0976 6.68342 21.7071 6.29289L17.7071 2.29289ZM14.7071 6.70711L17 4.41421L19.5858 7L15 11.5858L12.4142 9L14.7071 6.70711ZM5 16.4142L11 10.4142L13.5858 13L7.58579 19H5V16.4142Z"
							className="fill-current"
						></path>
					</svg>
					Add signature
				</Button>

				<div className="mt-4" />
				<Button
					onClick={() => {
						toggle();
						saveModalText();
					}}
				>
					<svg
						fill="none"
						viewBox="0 0 24 24"
						height="14"
						width="14"
						className="mr-2"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							xmlns="http://www.w3.org/2000/svg"
							d="M3 5C3 3.89543 3.89543 3 5 3H9H15H16.5858C17.1162 3 17.6249 3.21071 18 3.58579L20.7071 6.29289C20.8946 6.48043 21 6.73478 21 7V19C21 20.1046 20.1046 21 19 21H15H9H5C3.89543 21 3 20.1046 3 19V5ZM9 19H15V13H9V19ZM17 19H19V7.41421L17 5.41421V7C17 8.10457 16.1046 9 15 9H9C7.89543 9 7 8.10457 7 7V5H5V19H7V13C7 11.8954 7.89543 11 9 11H15C16.1046 11 17 11.8954 17 13V19ZM9 5V7H15V5H9Z"
							className="fill-current"
						></path>
					</svg>
					Save appeal
				</Button>

				<div className="mt-4" />
				<Button
					onClick={() => {
						toggle();
						insuranceModalText();
					}}
				>
					<svg
						fill="none"
						viewBox="0 0 24 24"
						height="14"
						width="14"
						className="mr-2"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							xmlns="http://www.w3.org/2000/svg"
							d="M12 2C12.3788 2 12.725 2.214 12.8944 2.55279L21.8944 20.5528C22.067 20.8978 22.0256 21.3113 21.7882 21.6154C21.5508 21.9195 21.1597 22.0599 20.7831 21.9762L12 20.0244L3.21694 21.9762C2.84035 22.0599 2.44921 21.9195 2.2118 21.6154C1.97439 21.3113 1.93306 20.8978 2.10558 20.5528L11.1056 2.55279C11.275 2.214 11.6212 2 12 2ZM13 18.1978L19.166 19.568L13 7.23607V18.1978ZM11 7.23607L4.83402 19.568L11 18.1978V7.23607Z"
							className="fill-current"
						></path>
					</svg>
					Send directly to insurance
				</Button>
			</div>

			<Modal isOpen={isOpen} close={toggle}>
				<div className="flex flex-col items-center justify-between p-8 w-letterWidth">
					<h2 className="text-2xl font-bold text-center">
						{modalTitle}
					</h2>
					<p className="text-center my-2">{modalDescription}</p>
					<InlineWidget
						url="https://calendly.com/intelliga/intelliga-health-demo"
						styles={{
							width: "100%",
							height: "500px",
						}}
					/>
				</div>
			</Modal>
		</div>
	);
};

export default StepThree;
