import React from "react";

const Footer = () => {
	return (
		<>
			<div className="w-full h-20" />
			<div className="w-full bg-white border border-top flex items-center fixed bottom-0 justify-between px-8 py-2">
				<p>© 2023, Intelliga Health LLC</p>
				<div className="flex items-center text-gray-400 text-sm italic">
					HIPAA compliance monitoring provided by Vanta.
					<img
						src="https://www.intelligahealth.com/Vanta.svg"
						className="ml-4 w-10 h-10"
					/>
				</div>
			</div>
		</>
	);
};

export default Footer;
