import { useEffect, useState } from "react";
import StepOne from "./steps/StepOne";
import StepTwo from "./steps/StepTwo";
import StepThree from "./steps/StepThree";
import TrialUpload from "./data/upload";
import GenerateAppeal from "./data/generateAppeal";
import ActivePage from "./components/ActivePage";
import Header from "./components/Header";
import CTA from "./components/CTA";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";

const HomePage = () => {
	const [currPage, setCurrPage] = useState(0);
	const [imagePath, setImagePath] = useState(null);
	const [fileMeta, setFileMeta] = useState(null);
	const [denialData, setDenialData] = useState(null);
	const [patientData, setPatientData] = useState(null);
	const [plainText, setPlainText] = useState(null);
	const [appeals, setAppeals] = useState(null);
	const [uploadLoading, setUploadLoading] = useState(false);

	const [firstName, setFirstName] = useState(patientData?.first_name);
	const [lastName, setLastName] = useState(patientData?.last_name);
	const [medication, setMedication] = useState(denialData?.g_medication_req);
	const [denialReason, setDenialReason] = useState(
		denialData?.g_treatment_denial_reason
	);

	useEffect(() => {
		setFirstName(patientData?.first_name);
		setLastName(patientData?.last_name);
	}, [patientData]);

	useEffect(() => {
		setMedication(denialData?.g_medication_req);
		setDenialReason(denialData?.g_treatment_denial_reason);
	}, [denialData]);

	const uploadFile = async (file) => {
		setFileMeta(file);
		console.log(file);
		setImagePath(file);
		setUploadLoading(true);
		handleClick(file);
	};

	const handleClick = async (path) => {
		setUploadLoading(true);
		const reader = new FileReader();
		reader.readAsDataURL(path);
		reader.onload = async () => {
			try {
				const base64Result = reader.result.split(",")[1];
				const { status, data } = await TrialUpload(base64Result);
				if (status) {
					console.log("Data is : ", data);
					setPatientData(data?.patient_info);
					setPlainText(data?.plain_text);
					setDenialData(data?.denialInfo);
					setCurrPage(currPage + 1);
					toast("Upload succesful", { type: "success" });
				}
				setUploadLoading(false);
			} catch (error) {
				console.log(error);
				setUploadLoading(false);
			}
		};
		reader.onerror = (error) => {
			console.log(error);
			setUploadLoading(false);
		};
	};
	const genAppeal = async (
		firstName,
		lastName,
		medication,
		denialReason,
		otherNotes
	) => {
		const { status, data } = await GenerateAppeal(
			firstName,
			lastName,
			plainText,
			medication,
			denialReason,
			otherNotes
		);

		if (status) {
			console.log(data);
			setAppeals(data);
			setCurrPage(currPage + 1);
		} else {
			console.log("Status upload error.");
		}

		return status;
	};

	return (
		<>
			<div className="h-screen py-12">
				<ToastContainer />
				<Header />

				<ActivePage
					currPage={currPage}
					setCurrPage={setCurrPage}
					className="w-full"
				/>

				{currPage === 0 && (
					<StepOne
						imagePath={imagePath}
						loading={uploadLoading}
						setLoading={setUploadLoading}
						uploadFile={uploadFile}
						setCurrPage={setCurrPage}
						handleClick={handleClick}
					></StepOne>
				)}
				{currPage === 1 && (
					<StepTwo
						genAppeal={genAppeal}
						firstName={firstName}
						setFirstName={setFirstName}
						lastName={lastName}
						setLastName={setLastName}
						medication={medication}
						setMedication={setMedication}
						setCurrPage={setCurrPage}
						file={fileMeta}
						denialReason={denialReason}
						setDenialReason={setDenialReason}
					></StepTwo>
				)}
				{currPage === 2 && (
					<StepThree
						appeals={appeals}
						setAppeals={setAppeals}
						setCurrPage={setCurrPage}
					></StepThree>
				)}

				<Footer />
			</div>
		</>
	);
};
export default HomePage;
