import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "./Button";
import Modal from "./Modal";
import { toast } from "react-toastify";

const FileUpload = ({ uploadFile, handleClick }) => {
	const inputFileRef = useRef(null);
	const [isDragging, setIsDragging] = useState(false);
	const [checked1, setChecked1] = useState(false);
	const [checked2, setChecked2] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [didSignConsent, setDidSignConsent] = useState(false);

	const handleUploadClick = () => {
		// Trigger the click event of the input file
		if (inputFileRef && inputFileRef.current) {
			inputFileRef.current.click();
		}
	};

	const handleDrop = useCallback(
		(e) => {
			e.preventDefault();
			if (e.dataTransfer.items) {
				for (let i = 0; i < e.dataTransfer.items.length; i++) {
					if (e.dataTransfer.items[i].kind === "file") {
						const file = e.dataTransfer.items[i].getAsFile();
						uploadFile(file);
					}
				}
			}
		},
		[uploadFile]
	);

	const handleDragOver = useCallback((e) => {
		e.preventDefault();
		setIsDragging(true);
	}, []);

	const handleDragLeave = useCallback(() => {
		setIsDragging(false);
	}, []);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!didSignConsent) {
				setIsOpen(true);
			}
		}, 2000);

		return () => {
			clearTimeout(timer); // Clear the timer if the component is unmounted or the effect is cleaned up
		};
	}, [didSignConsent]);

	const handleEnterWebsiteClick = () => {
		setIsOpen(false);

		if (typeof Storage !== "undefined") {
			// Store the string in local storage
			localStorage.setItem("didSignConsent", true);
		} else {
			// Local storage is not supported
			console.log("Sorry, your browser does not support local storage.");
		}

		toast("Success. You're in!", { type: "success" });
		setDidSignConsent(true);
	};

	useEffect(() => {
		// Retrieve the string from local storage
		const signedConsent = localStorage.getItem("didSignConsent");

		if (signedConsent) {
			setDidSignConsent(true);
		}
	}, []);

	return (
		<div>
			<Modal isOpen={isOpen} close={() => {}}>
				<div className="flex flex-col">
					<div className="mt-4 w-full flex flex-col w-modalWidth">
						<p className="text-center text-lg">
							You must read and agree to the following documents
							before using Inteliga Health's Appeal Suite.
						</p>

						<div className="w-24 h-24 bg-gray mt-8 self-center flex justify-center items-center rounded-full">
							<svg
								fill="none"
								viewBox="0 0 24 24"
								height="48"
								width="48"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									xmlns="http://www.w3.org/2000/svg"
									d="M4 4C4 2.89543 4.89543 2 6 2H14C14.2652 2 14.5196 2.10536 14.7071 2.29289L19.7071 7.29289C19.8946 7.48043 20 7.73478 20 8V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V4ZM17.5858 8L14 4.41421V8H17.5858ZM12 4L6 4V20H18V10H13C12.4477 10 12 9.55228 12 9V4ZM8 13C8 12.4477 8.44772 12 9 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H9C8.44772 14 8 13.5523 8 13ZM8 17C8 16.4477 8.44772 16 9 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H9C8.44772 18 8 17.5523 8 17Z"
									fill="#fff"
								></path>
							</svg>
						</div>

						<div className="flex items-center mt-8 mb-4">
							<a
								href="https://docs.google.com/document/d/1nanhJXGXwY1USoa-8y0MUWzIbbMq7C4wAYB2YIGVA3Y/edit?usp=sharing"
								className="font-bold mr-2"
								target="_blank"
							>
								I agree to the{" "}
								<span className="underline">
									business associates agreement
								</span>{" "}
								(BAA)
							</a>
							<Checkbox
								checked={checked1}
								setChecked={setChecked1}
							/>
						</div>
						{/* <div className="flex items-center mt-2 mb-4">
							<a href="#" className="font-bold mr-2">
								I agree to the{" "}
								<span className="underline">
									terms of service
								</span>
							</a>
							<Checkbox
								checked={checked2}
								setChecked={setChecked2}
							/>
						</div> */}

						{checked1 ? (
							<Button onClick={handleEnterWebsiteClick}>
								Continue to website
							</Button>
						) : (
							<div
								className="opacity-50 flex justify-center items-center"
								onClick={() =>
									toast("Please agree to the terms", {
										type: "error",
									})
								}
							>
								<Button>Continue to website</Button>
							</div>
						)}
					</div>
				</div>
			</Modal>

			{(checked1 && checked2) || didSignConsent ? (
				<div
					className={`flex items-center block justify-center w-letterWidth`}
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
				>
					<label
						for="dropzone-file"
						className={`flex flex-col items-center justify-center w-full border-2 ${
							isDragging
								? "border border-gray"
								: "border-background3"
						}  rounded-sm cursor-pointer bg-slate-50 border-dashed`}
					>
						<div className="flex flex-col items-center justify-center py-16">
							<svg
								aria-hidden="true"
								className="w-14 h-14 mb-3 mt-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
								></path>
							</svg>
							<p className="text-4xl">
								<span className="font-semibold">
									Drag and Drop
								</span>
							</p>
							<p className="text-2xl my-6">or</p>

							<div className="w-full flex items-center justify-center">
								<Button onClick={handleUploadClick}>
									Upload denial letter
								</Button>
							</div>
						</div>
						<input
							ref={inputFileRef}
							id="dropzone-file"
							onChange={(event) =>
								uploadFile(event.target.files[0])
							}
							accept="application/pdf"
							type="file"
							className="hidden z-[9999]"
						/>
					</label>
				</div>
			) : (
				<div
					className={`flex items-center block justify-center w-letterWidth opacity-50`}
				>
					<label
						for="dropzone-file"
						className={`flex flex-col items-center justify-center w-full border-2 cursor-not-allowed ${
							isDragging
								? "border border-gray"
								: "border-background3"
						}  rounded-sm bg-slate-50 border-dashed`}
					>
						<div className="flex flex-col items-center justify-center py-16">
							<svg
								aria-hidden="true"
								className="w-14 h-14 mb-3 mt-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
								></path>
							</svg>
							<p className="text-4xl">
								<span className="font-semibold">
									Drag and Drop
								</span>
							</p>
							<p className="text-2xl my-6">or</p>

							<div className="w-full flex items-center justify-center">
								<Button>Upload denial letter</Button>
							</div>
						</div>
					</label>
				</div>
			)}
		</div>
	);
};

const Checkbox = ({ checked, setChecked }) => {
	return (
		<>
			<button
				className={`h-4 w-4 border border-grayBorder rounded-none cursor-pointer flex items-center justify-center bg-gray1`}
				role="checkbox"
				tabIndex={0}
				aria-checked={checked}
				onClick={() => setChecked(!checked)}
			>
				<svg
					fill={checked ? "black" : "none"}
					viewBox="0 0 24 24"
					height="24"
					width="24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						xmlns="http://www.w3.org/2000/svg"
						d="M20.6644 5.2526C21.0772 5.61952 21.1143 6.25159 20.7474 6.66437L10.0808 18.6644C9.89099 18.8779 9.61898 19 9.33334 19C9.04771 19 8.7757 18.8779 8.58593 18.6644L3.2526 12.6644C2.88568 12.2516 2.92286 11.6195 3.33565 11.2526C3.74843 10.8857 4.3805 10.9229 4.74742 11.3356L9.33334 16.4948L19.2526 5.33565C19.6195 4.92286 20.2516 4.88568 20.6644 5.2526Z"
					></path>
				</svg>
			</button>

			<input
				type="checkbox"
				className="hidden"
				checked={checked}
				onChange={() => setChecked(!checked)}
			/>
		</>
	);
};

export default FileUpload;
