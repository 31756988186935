import React, { useState } from "react";
import Logo from "./Logo";
import Button from "./Button";
import Modal from "./Modal";
import { InlineWidget } from "react-calendly";

const Header = () => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			<div className="w-full flex justify-between items-center text-center px-24">
				<Logo />
				<div className="flex items-center">
					<div className="mr-8">
						<Button
							inline
							href="https://intelligahealth.com"
							target="_blank"
						>
							About us
						</Button>
					</div>

					<div className="mr-8">
						<Button inline selected href="/">
							Appeal Suite
						</Button>
					</div>

					<Button alt onClick={toggle}>
						Book demo
					</Button>
				</div>
			</div>

			<Modal isOpen={isOpen} close={toggle}>
				<div className="flex flex-col items-center justify-between p-8 w-letterWidth">
					<h2 className="text-2xl font-bold">Book a demo</h2>
					<p className="text-center my-2">
						See the full Intelliga Health platform in action.
					</p>
					<InlineWidget
						url="https://calendly.com/intelliga/intelliga-health-demo"
						styles={{
							width: "100%",
							height: "500px",
						}}
					/>
				</div>
			</Modal>
		</>
	);
};

export default Header;
