import React from "react";

const Logo = () => {
	return (
		<a href="https://intelligahealth.com" className="text-darkBlue text-lg">
			<span className="font-bold">INTELLIGA</span> HEALTH
		</a>
	);
};

export default Logo;
