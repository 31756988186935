import React from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { Link } from "react-router-dom";

const Button = ({
	children,
	onClick,
	loading = false,
	alt = false,
	href,
	inline = false,
	selected = false,
	target = "",
}) => {
	const buttonClass = alt
		? "h-12 shadow-sm hover:shadow-md px-8 bg-white text-darkBlue text-center hover:bg-darkBlue hover:text-white border border-darkBlue transition-all flex items-center justify-center"
		: "h-14 px-16 bg-gray text-white text-center hover:opacity-90 transition-all flex items-center justify-center";

	if (inline) {
		if (selected) {
			return (
				<Link href={href} className="underline font-bold">
					{children}
				</Link>
			);
		}
		return (
			<a
				href={href}
				className="hover:opacity-100 opacity-70 transition-all duration-100"
				target={target}
			>
				{children}
			</a>
		);
	}

	if (href) {
		return (
			<a href={href} className={buttonClass} target="_blank">
				{children}
			</a>
		);
	}

	if (!onClick) {
		return (
			<button className="h-14 px-16 bg-gray text-white text-center flex items-center justify-center cursor-not-allowed">
				{loading && (
					<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
				)}
				{children}
			</button>
		);
	}

	return (
		<button onClick={onClick} className={buttonClass}>
			{loading && (
				<AiOutlineLoading className="animate-spin mr-2.5 text-xl my-auto" />
			)}
			{children}
		</button>
	);
};

export default Button;
