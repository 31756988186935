import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import ProgressBar from "../components/ProgressBar";
import { toast } from "react-toastify";

const TOTAL_TIME = 60; // 20 seconds
const MAX_PERCENTAGE = 95;

const StepTwo = ({
	denialReason,
	setDenialReason,
	firstName,
	setFirstName,
	lastName,
	setLastName,
	medication,
	setMedication,
	otherNotes,
	setOtherNotes,
	genAppeal,
	file,
}) => {
	const [loading, setLoading] = useState(false);

	const handleGenerateAppeal = async () => {
		setLoading(true);
		const status = await genAppeal(
			firstName,
			lastName,
			medication,
			denialReason,
			otherNotes
		);
		setLoading(false);

		if (status) {
			toast("Appeal generated", { type: "success" });
		} else {
			toast("Error generating appeal", { type: "success" });
		}
	};

	const inpSty =
		"py-1.5 px-3.5 bg-slate-50 border-[1.5px] rounded-[1px] border-grayBorder mt-1.5 transition-all focus:border-gray outline-none";

	const [fileUrl, setFileUrl] = useState(null);

	useEffect(() => {
		// Create a URL for the file
		setFileUrl(URL.createObjectURL(file) + "#toolbar=0&navpanes=0");
	}, [file]);

	const [percentage, setPercentage] = useState(1);

	useEffect(() => {
		const interval = setInterval(() => {
			setPercentage((oldPercentage) => {
				if (oldPercentage >= MAX_PERCENTAGE) {
					clearInterval(interval);
					return oldPercentage;
				}
				return oldPercentage + 1;
			});
		}, TOTAL_TIME * 10); // progresses 1% every 200ms

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (!loading) {
			setPercentage(1);
		}

		return () => {
			setPercentage(1);
		};
	}, [loading]);

	return (
		<div className="w-full flex flex-col items-center">
			<div className="w-3/4 mx-auto flex">
				<div className="w-full flex flex-col">
					<p style={{ color: "#6E6E72" }}>
						The following details form the basis of a medically
						justified prior authorization appeal letter. Confirm the
						information is totally accurate.
					</p>

					<div className="w-full flex my-8">
						<div className="flex flex-col w-full">
							<div className="flex flex-row w-full space-x-24">
								<div className="flex flex-col w-1/2">
									<p className="text-sm font-bold">
										First name
									</p>
									<input
										value={firstName}
										onChange={(e) =>
											setFirstName(e.target.value)
										}
										className={inpSty}
									/>
								</div>
								<div className="flex flex-col w-1/2">
									<p className="text-sm font-bold">
										Last name
									</p>
									<input
										value={lastName}
										onChange={(e) =>
											setLastName(e.target.value)
										}
										className={inpSty}
									/>
								</div>
							</div>
							<div className="flex flex-col mt-4 w-full">
								<p className="text-sm font-bold">
									Denied Treatment or Service
								</p>
								<input
									value={medication}
									onChange={(e) =>
										setMedication(e.target.value)
									}
									className={inpSty}
								/>
							</div>
							<div className="flex flex-col mt-4 w-full">
								<p className="text-sm font-bold">
									Denial Reason
								</p>
								<textarea
									value={denialReason}
									onChange={(e) =>
										setDenialReason(e.target.value)
									}
									className={inpSty}
									rows={5}
								/>
							</div>
						</div>
					</div>

					{loading && (
						<div className="w-full mb-4">
							<ProgressBar percentage={percentage} />
						</div>
					)}

					<Button onClick={handleGenerateAppeal} loading={loading}>
						Generate appeal
					</Button>
				</div>

				<div className="w-[3px] h-96 bg-gray-200 mx-10 rounded-sm my-auto"></div>

				<div className="flex flex-col w-3/5 mt-0">
					<p className="text-sm font-bold">Letter preview</p>
					<div className="w-full bg-white py-6 h-[420px] mt-1.5 border border-gray-300 flex items-center flex-col">
						<div className="w-3/4 mb-4">
							Details are automatically retrieved from the denial.
						</div>
						<iframe
							src={fileUrl}
							style={{
								width: "75%",
								height: "300px",
								margin: "0 auto",
							}}
							title="PDF viewer"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepTwo;
