import { useEffect, useState } from "react";

const ActivePage = ({ currPage, setCurrPage }) => {
	const [currentStep, setCurrentStep] = useState(0);

	const pages = [
		{
			name: "Step 1: Upload Denial",
		},
		{
			name: "Step 2: Confirm Details",
		},
		{
			name: "Step 3: Review Appeal Letter",
		},
	];

	const handlePageChange = (idx) => {
		if (idx <= currentStep) {
			setCurrPage(idx);
		}
	};

	useEffect(() => {
		if (currPage > currentStep) {
			setCurrentStep(currPage);
		}
	}, [currPage, currentStep]);

	return (
		<div className="flex w-2/3 mx-auto mb-8 mt-12">
			{pages?.map((key, idx) => {
				return (
					<div
						className={`w-1/3 text-center my-auto font-bold ${
							currPage === idx
								? "text-gray text-xl my-auto"
								: "text-gray-400 text-sm"
						}
						
						${idx <= currentStep ? "cursor-pointer" : "cursor-not-allowed"}
						`}
						onClick={() => handlePageChange(idx)}
					>
						{key?.name}
					</div>
				);
			})}
		</div>
	);
};
export default ActivePage;
